import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Contact } from './Contact';
import * as styles from './Socials.style.scss';

export const Socials = () => (
  <ul className={styles.socials}>
    <li>
      <a href="https://github.com/kennethlynne" target="_blank">
        <FontAwesomeIcon icon={faGithub} />
        <span>GitHub</span>
      </a>
    </li>
    <li>
      <a href="https://www.linkedin.com/in/kennethlynne/" target="_blank">
        <FontAwesomeIcon icon={faLinkedin} />
        <span>LinkedIn</span>
      </a>
    </li>
    <li>
      <Contact />
    </li>
  </ul>
);
