import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import * as styles from './Contact.style.scss';

declare const $crisp: any[];
export const Contact = () => {
  const triggerContact = useCallback(() => {
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'chat:show']);
  }, []);

  return (
    <a className={styles.contact} onClick={triggerContact} title={'Chat with me'}>
      <FontAwesomeIcon icon={faComments} />
      <span>Chat</span>
    </a>
  );
};
