import 'normalize.css/normalize.css';
import * as React from 'react';
import { render } from 'react-dom';
import App from './App.component';

render(<App />, document.getElementById('root'));

if ((module as any).hot) {
  // tslint:disable-next-line: no-console
  console.log('Hot module reloading enabled');
  (module as any).hot.accept();
}
