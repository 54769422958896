import React, { useCallback, useState } from 'react';
import { Screen } from '../components/Screen';
import { HomeScreen } from '../screens/Home/HomeScreen';
import { IntroScreen } from '../screens/Intro/IntroScreen';

export const Index = () => {
  const [loading, setLoading] = useState(true);
  const onIntroComplete = useCallback(() => setLoading(false), [setLoading]);

  return (
    <Screen>
      {loading ? <IntroScreen onIntroComplete={onIntroComplete} /> : null}
      {!loading ? <HomeScreen /> : null}
    </Screen>
  );
};
