import { Client } from '@bugsnag/browser/dist/types/bugsnag-core';
import bugsnagReact from '@bugsnag/plugin-react';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { hot } from 'react-hot-loader';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import * as styles from './App.style.scss';
import { GOOGLE_ANALYTICS_TRACKING_CODE } from './config';
import { Index } from './routes/Index';

if (GOOGLE_ANALYTICS_TRACKING_CODE) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE);
}

const { bugsnag } = (window as any) as { bugsnag: Client };

if (bugsnag) {
  bugsnag.use(bugsnagReact, React);
} else {
  throw new Error(`Unable to initialise bugsnag. Expected window.bugsnag to be defined`);
}

const ErrorBoundary = bugsnag.getPlugin('react');

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    if (GOOGLE_ANALYTICS_TRACKING_CODE) {
      ReactGA.set(Object.assign({}, options, { page }));
      ReactGA.pageview(page);
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [`${props.location.pathname}`]);

    return <WrappedComponent {...props} />;
  };
};

const App = () => (
  <ErrorBoundary>
    <div className={classNames(styles.container)}>
      <Router>
        <Switch>
          <Route path="/nne" exact={true} component={withTracker(Index)} />
          <Redirect from="/" to="/nne" />
        </Switch>
      </Router>
    </div>
  </ErrorBoundary>
);

export default hot(module)(App);
