import classNames from 'classnames';
import { delay } from 'q';
import React, { useEffect, useState } from 'react';
import * as styles from './Typewriter.style.scss';

const rnd = (min: number, max: number) => {
  return Math.round(Math.random() * max + min);
};

export const Typewriter = (props: { lines: { text: string; ttl?: number }[] }) => {
  const [msg, setMsg] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const fn = async () => {
      let message = msg;
      for (const item of props.lines) {
        let toType = item.text;

        if (message) {
          // we need to remove the previous message
          setIsTyping(true);
          // tslint:disable-next-line: forin
          backspace: for (const _ in message.split('')) {
            message = message.slice(0, message.length - 1);
            setMsg(message);
            if (message.length > 0 && toType.startsWith(message)) {
              toType = toType.slice(message.length - 1);
              break backspace;
            }

            await delay(rnd(30, 40));
          }
          setIsTyping(false);

          await delay(rnd(50, 100));
        }

        setIsTyping(true);
        for (const character of toType) {
          message = message + character;
          setMsg(message);
          await delay(rnd(50, 100));
        }
        setIsTyping(false);

        if (item.ttl) {
          await delay(item.ttl);
        }
      }
      setIsFinished(true);
    };
    fn();
  }, []);

  return (
    <div className={classNames([styles.container])}>
      <span>{msg}</span>
      <span
        className={classNames([
          styles.cursor,
          !isTyping && styles.blinking,
          isFinished && styles.hide,
        ])}>
        |
      </span>
    </div>
  );
};
