import React from 'react';
import { Foreground } from '../../components/Foreground';
import { Logo } from '../../components/Logo';
import { Typewriter } from '../../components/Typewriter';
import { Background } from './components/Background/Background';
import { Socials } from './components/Socials/Socials';
import * as styles from './HomeScreen.style.scss';

const Main = () => {
  return (
    <main className={styles.main}>
      <Logo />
      <Typewriter
        lines={[
          { text: '', ttl: 3000 },
          { text: "I'm a back-end d", ttl: 200 },
          { text: "I'm a front-end dev", ttl: 100 },
          { text: "I'm a full-stack developer", ttl: 2000 },
          { text: "I'm a React n", ttl: 200 },
          { text: "I'm a React and React-Native nerd", ttl: 2000 },
          { text: "I'm a DevOps engineer", ttl: 1200 },
          { text: "I'm a problem solver.", ttl: 1000 },
        ]}
      />
      <Socials />
    </main>
  );
};

export const HomeScreen = () => (
  <div className={styles.container}>
    <Foreground />
    <Main />
    <Background />
  </div>
);
