import anime from 'animejs';
import React, { useEffect } from 'react';
import { nonBreakingSpace } from '../../../../lib/nonBreakingSpace';
import * as styles from './Intro.style.scss';

export const Intro = (props: { onComplete?: () => void }) => {
  useEffect(() => {
    const animation = anime
      .timeline()
      .add({
        targets: `.${styles.underline}`,
        scaleX: [0, 1],
        opacity: [0.5, 1],
        easing: 'easeInOutQuint',
        duration: 2800,
      })
      .add(
        {
          targets: `.${styles.letter}`,
          opacity: [0, 1],
          translateY: [30, 0],
          translateZ: 0,
          scaleX: [0.5, 1],
          easing: 'easeInOutQuint',
          duration: 2000,
          delay: (_, i) => 150 + 25 * i,
        },
        '-=2800',
      )
      .add(
        {
          targets: `.${styles.loadingIndicator}`,
          scaleX: [0, 1],
          easing: 'easeInOutQuad',
          duration: 1000,
        },
        '-=800',
      )
      .add({
        targets: `.${styles.letter}`,
        opacity: [1, 0],
        translateY: [0, -10],
        translateZ: 0,
        scaleX: [1, 0.9],
        easing: 'easeInOutQuint',
        duration: 800,
        delay: (_, i) => 150 + 25 * i,
      })
      .add(
        {
          targets: `.${styles.underline}`,
          scaleX: [1, 0],
          left: ['0$', '100%'],
          opacity: [1, 0.5],
          easing: 'easeInOutQuint',
          duration: 800,
        },
        '-=800',
      )
      .add(
        {
          targets: `.${styles.loadingIndicator}`,
          scaleX: [1, 0],
          left: ['0$', '100%'],
          easing: 'easeInOutQuad',
          duration: 800,
        },
        '-=800',
      );

    animation.finished.then(props.onComplete);
  }, []);

  return (
    <div className={styles.container}>
      <span className={styles.letters}>
        {'kenneth lynne'.split('').map((c, k) => (
          <span className={styles.letter} key={k}>
            {c === ' ' ? nonBreakingSpace : c}
          </span>
        ))}
        <div className={styles.loadingIndicator} />
        <div className={styles.underline} />
      </span>
    </div>
  );
};
