import anime from 'animejs';
import React, { useCallback, useRef } from 'react';
import { Intro } from './components/Intro/Intro';
import * as styles from './IntroScreen.style.scss';

export const IntroScreen = (props: { onIntroComplete: () => void }) => {
  const wrapper = useRef(null);

  const complete = useCallback(async () => {
    await anime.timeline().add({
      targets: wrapper.current,
      opacity: [1, 0],
      easing: 'easeInOutQuint',
      duration: 1000,
    }).finished;

    props.onIntroComplete();
  }, [wrapper]);

  return (
    <div className={styles.container} ref={wrapper}>
      <Intro onComplete={complete} />
    </div>
  );
};
