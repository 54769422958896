import React from 'react';
import * as styles from './Logo.style.scss';

export const Logo = () => (
  <div className={styles.logo}>
    <div className={styles.row}>
      {'KENNETH'.split('').map((l, i) => (
        <span key={i}>{l}</span>
      ))}
    </div>
    <div className={styles.row}>
      {'LYNNE'.split('').map((l, i) => (
        <span key={i}>{l}</span>
      ))}
    </div>
  </div>
);
